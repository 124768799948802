<script setup>
defineProps({
    data: {
        type: Object,
        default: () => ({}),
    },
});

const { navs } = useSharedState();
</script>
<template>
    <Parallax
        :enter="false"
        :leave="false"
        :animation="false"
        enter-leave-wrapper-classes="relative mb-25 flex overflow-hidden bg-nuit px-7 pt-14 min-h-[100svh] pb-24 lg:h-screen lg:items-center lg:px-17 lg:py-0"
    >
        <ul
            class="absolute bottom-0 z-1 flex w-full gap-4 text-white lg:top-1 lg:justify-end lg:px-28 lg:pt-11 lg:text-right"
        >
            <li
                v-for="(link, index) in navs.socials"
                :key="link.id"
                :data-index="index + navs.socials.length"
                class="mb-1"
            >
                <a
                    :href="link.uri"
                    class="inline-block fill-current"
                    target="_blank"
                    v-html="link.icon.inline"
                />
            </li>
        </ul>
        <ParallaxChild
            v-if="data?.image?.[0]?.url"
            class="absolute bottom-24 right-0 z-0 h-[50vh] w-full lg:bottom-0 lg:size-full"
            enter-leave-wrapper-classes="absolute inset-0 size-full"
            :animation="{ transform: ['translateY(0px) translateZ(0)', 'translateY(250px) translateZ(0)'] }"
        >
            <img
                class="absolute bottom-0 right-0 h-[50vh] w-auto translate-x-[25%] object-cover lg:size-full lg:translate-x-[8%] lg:object-cover"
                :src="data.image[0].url"
                :srcset="data.image[0].srcset"
                :alt="data.image[0].alt"
            >
        </ParallaxChild>
        <div class="z-1 lg:w-143">
            <div v-if="data.sectionTitle" class="mb-6 text-6xl font-bold uppercase lg:text-10xl">
                <SplitText
                    :animation="false"
                    :delay-init="true"
                >
                    <h1 class="leading-none" v-html="parseMarkdown(nl2br(data.sectionTitle))" />
                </SplitText>
            </div>
            <ParallaxChild
                v-if="data.textContent"
                :animation="false"
                :enter-options="{delay:0.15}"
            >
                <div
                    class="wysiwyg mb-6 w-3/4 font-serif text-base lg:text-2xl"
                    v-html="data.textContent"
                />
            </ParallaxChild>
            <ParallaxChild
                :animation="false"
                :enter-options="{delay:0.3}"
            >
                <Button
                    v-for="link in data.links"
                    :key="link.id"
                    :to="link.url"
                >
                    <span v-html="link.linkText" />
                    <span class="ml-2 inline-block fill-current" v-html="link.icon.inline" />
                </Button>
            </ParallaxChild>
        </div>
    </Parallax>
</template>
